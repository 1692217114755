<template>
    <span>
        <div class="row">
            <notifications></notifications>

            <div class="col-sm-12 col-md-8 mx-auto mt-3">
<!--                <div class="alert alert-danger">-->
              <!--                  System down for maintenance and migration to new servers. Expect return to service Wednesday, August 16, 2023.-->
              <!--                </div>-->
                <card class="card-user" no-footer-line>
                    <div>
                        <div class="text-center">
                          <img class="w-50" src="@/assets/img/logos/natca-logo-white-bg.png" alt="">
                          <h1>Welcome to BID</h1>
                          <p class="text-left">
                            BID now utilizes MyNATCA accounts to handle user accounts and login.
                            You may <strong><a href="#" @click="$auth.loginWithRedirect()">LOGIN</a></strong> with your MyNATCA account or
                            <strong><a href="https://my.natca.org/register">REGISTER</a></strong> for an account.
                          </p>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </span>
</template>
<script>
export default {
  name: 'home',
  components: {},
  data () {
    return {
      email: ''
    }
  },
  methods: {},
  computed: {},
  mounted () {
  },
  created () {
  }
}
</script>
<style>
.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}
</style>
